import './ClientLogs.css';
import React, {Component} from 'react';
import {fetchDownload} from "../Utils";
var FileSaver = require('file-saver');


class ClientLogs extends Component {


    render() {
        let hasLogs = this.props.hasLogs;
        let id = this.props.id;
        function handleClick(e) {
            e.preventDefault();
            let file_name = 'clientlog' + id + '.zip';
            let url = '/controller/clientlogs/download/'+ id;
            let fetchHelperClientlogs = fetchDownload(url);
            let endpointClientlogs = fetchHelperClientlogs[0];
            let lookupOptionsClientlogs = fetchHelperClientlogs[1];
            fetch(endpointClientlogs, lookupOptionsClientlogs)
                .then(response=> {
                    return response.blob();
                }).then(blob => {
                FileSaver.saveAs(blob, file_name);
            }).catch(error=>console.log(error));
        }

        let log = hasLogs === true?
            <div className="menu_links">
                <span onClick={handleClick} role='img' aria-label='floppy disk'>
                    &#128190;
                </span>
            </div>:
            <span role='img' aria-label='no entry'>&#9940;</span>;

        return (
            <div>{log}</div>
        );
    }
}



export default ClientLogs;