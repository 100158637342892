import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLargehootStats} from "../../redux/actions";
import SingleLargehootStatsComp from "./SingleLargehootStatsComp";

class LargehootStats extends Component {
    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getLargehootStats(idx);
    }

    render() {
        const val = this.props.largehoot_stats;
        const idx = this.props.match.params.idx;
        const errors = this.props.peer_stats_error_types;
        const users = this.props.users;
        return (
            <SingleLargehootStatsComp val={val} idx={idx} errors={errors} users={users} />
        );
    }
}

function mapStateToProps(state) {
    return {
        largehoot_stats: state.largehoot_stats,
        peer_stats_error_types: state.peer_stats_error_types,
        users: state.users,
    };
}

const LargehootStatsConnect = connect(mapStateToProps, {getLargehootStats})(LargehootStats);

export default LargehootStatsConnect;