import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PeerStatsTable from "./PeerStatsTable";

class SinglePeerStatsComp extends Component {

    static propTypes = {
        val: PropTypes.array.isRequired,
        idx: PropTypes.string.isRequired,
        errors: PropTypes.array.isRequired,
        users: PropTypes.array.isRequired,
    };

    render() {
        const val = this.props.val;
        const idx = this.props.idx;
        const errors = this.props.errors;
        const users = this.props.users;
        return (
            <div>
                <h1>PeerStats Test with Test Num {idx}</h1>
                <PeerStatsTable val={val} peer_stats_error_types={errors} users={users}/>
            </div>
        );
    }
}

export default SinglePeerStatsComp;