import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';
import AllTestsTable from './AllTestsTable';
import {connect} from 'react-redux';
import {getAllPageInfoForTests} from "../../redux/actions";
import {getPageTestsFromNextButton} from "../../redux/actions";
import {getPageTestsFromPrevButton} from "../../redux/actions";
import SearchField from "react-search-field"
import {PAGE_QUERY} from "../../redux/constants/action-types"


class AllPageTempTests extends Component {

    state = {
       current_page: 1,
    }

    componentDidMount() {
       this.props.getAllPageInfoForTests(this.state.current_page);
    }

     // get the test result based on the page number you enter
     search = (target_page) => {
         target_page = parseInt(target_page)
         if (target_page <= 0) {
             return;
         }
         if ((target_page in this.props.test_map) === true) {
             if (this.props.test_map[target_page].length === 0) {
                 return;
             }
             else {
                 this.props.getPageTestsFromNextButton(target_page);
                 return;
             }
         }
         else {
             const url = PAGE_QUERY + target_page
             this.props.getPageTestsFromNextButton(target_page, url);
         }
     }

     // load the previous page of test result
     loadPrev = () => {
         var prev_page = this.props.current_page - 1;
         const url = PAGE_QUERY + prev_page
         if (prev_page === 0) {
             return;
         }
         else {
             this.props.getPageTestsFromPrevButton(prev_page, url);
         }
     }

    // load the next page of test result
     loadNext = () => {
         var next_page = this.props.current_page + 1
         const url = PAGE_QUERY + next_page
         if (next_page in this.props.test_map) {
             if (this.props.test_map[next_page].length !== 0) {
                 this.props.getPageTestsFromNextButton(next_page);
                 return;
             }
             else {
                 return;
             }

         }
         else {
            // if prev page has no record, then do nothing and return
             if (next_page > 1) {
                 if (this.props.test_map[next_page - 1].length === 0) {
                     return;
                 }
             }
             this.props.getPageTestsFromNextButton(next_page, url);
         }
     }



    render(){
        var tests = this.props.tests;
        const users = this.props.users;
        var current_page = this.props.current_page;
        if (current_page in this.props.test_map) {
            tests = this.props.test_map[current_page]
        }
        return (
            <div>
                <h1> All Test Cases Table </h1>
                <Table striped bordered hover size="sm" variant="dark">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>testers</th>
                            <th>test_start_time</th>
                            <th>do_ice_stats_test</th>
                            <th>do_peer_stats_test</th>
                            <th>do_press_button_test</th>
                            <th>do_intercom</th>
                            <th>do_ringdown</th>
                            <th>do_largehoot</th>
                            <th>has_client_logs</th>
                            <th>succeed</th>
                        </tr>
                    </tbody>
                    <tbody>
                    {
                        tests.map((test)=>{
                            const string_num = String(test.num_of_test_case);
                            return  (
                                <AllTestsTable general_test={test} users={users}
                                               key={"num_of_test_case" + string_num} />
                            )
                        })
                    }
                    </tbody>
                </Table>
                <h3> {this.props.current_page} </h3>
                <button onClick = {this.loadPrev} class = "prev_button"> prev </button>
                <SearchField onEnter= {this.search} onSearchClick = {this.search} />
                <button onClick = {this.loadNext} class = "next_button"> next </button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        test_map: state.test_map,
        tests: state.tests,
        users: state.users,
        current_page: state.current_page,
        peer_stats_error_types: state.peer_stats_error_types,
    };
}

const AllPageTestsConnect = connect(mapStateToProps, {getAllPageInfoForTests, getPageTestsFromNextButton, getPageTestsFromPrevButton})(AllPageTempTests);

export default AllPageTestsConnect;