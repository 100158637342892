import {
    ASYNC_LOAD_USER,
    ERROR,
    ASYNC_LOAD_ICE_STATS,
    ASYNC_LOAD_PEER_STATS,
    GET_SINGLE_PEER_STATS,
    GET_SINGLE_TEST,
    ASYNC_LOAD_GENERAL_STATS,
    GET_USER_INFO,
    GET_BOTH_USER_AND_TESTS,
    GET_SINGLE_USER,
    GET_SINGLE_ICE,
    GET_ERROR_TYPE,
    GET_SINGLE_GENERAL, DOWNLOAD_CLIENT_LOGS,
    GET_PREV_PAGE,
    GET_NEXT_PAGE,
    GET_INTERCOM_STATS,
    GET_LARGEHOOT_STATS,
    GET_RINGDOWN_STATS
} from "../constants/action-types";


const initialState = {
    users: [],
    ice_stats: [],
    tests: [],
    peer_stats_info: [],
    single_user: {},
    single_ice: [],
    single_peerstats: [],
    intercom_stats: [],
    ringdown_stats: [],
    largehoot_stats: [],
    single_test: [],
    peer_stats_error_types: [],
    single_general: {},
    test_map: {},
    current_page: 1,
};

function rootReducer(state = initialState, action) {

    switch (action.type) {
        case ASYNC_LOAD_USER:
            return Object.assign({}, state, {
                users: state.users.concat(action.payload),
            });

        case ASYNC_LOAD_ICE_STATS:
            return Object.assign({}, state, {
                ice_stats: state.ice_stats.concat(action.payload),
            });

        case ASYNC_LOAD_GENERAL_STATS:
            return Object.assign({}, state, {
                tests: state.tests.concat(action.payload),
            });

        case ASYNC_LOAD_PEER_STATS:
            return Object.assign({}, state, {
                peer_stats_info: state.peer_stats_info.concat(action.payload),
            });

        // case GET_BOTH_USER_AND_TESTS:
        //     return Object.assign({}, state, {
        //         tests: state.tests.concat(action.payload),
        //         users: state.users.concat(action.payload),
        //     });

        case GET_SINGLE_USER:
            return Object.assign({}, state, {
               single_user: action.payload,
            });

        case GET_SINGLE_ICE:
            return Object.assign({}, state, {
                single_ice: action.payload,
            });

        case GET_INTERCOM_STATS:
            return Object.assign({}, state, {
                intercom_stats: action.payload,
            });

        case GET_RINGDOWN_STATS:
            return Object.assign({}, state, {
                ringdown_stats: action.payload,
            });

        case GET_LARGEHOOT_STATS:
            return Object.assign({}, state, {
                largehoot_stats: action.payload,
            });

        case GET_SINGLE_PEER_STATS:
            return Object.assign({}, state, {
                single_peerstats: action.payload,
            });

        // case GET_SINGLE_TEST:
        //     return Object.assign({}, state, {
        //        single_test: action.payload,
        //     });

        case GET_ERROR_TYPE:
            return Object.assign({}, state, {
                peer_stats_error_types: state.peer_stats_error_types.concat(action.payload),
            });

        case GET_SINGLE_GENERAL:
            return Object.assign({}, state, {
               single_general: action.payload,
            });

        case GET_USER_INFO:

        case GET_PREV_PAGE:
            return Object.assign({}, state, {

            });

        case GET_NEXT_PAGE:
            return Object.assign({}, state, {

            });


        case ERROR:
        default:
            return state;
    }
}

export default rootReducer;
