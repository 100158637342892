import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';
import './UserInfo.css';
import {connect} from "react-redux";
import {getSingleUser} from "../../redux/actions";


class SingleUserInfo extends Component {

    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getSingleUser(idx);
    }


    render() {
        const users = this.props.single_user;
        const id = users.id;
        const username = users.username;
        const password = users.password;
        const company = users.company;
        return (
            <div>
                <h1> {username} </h1>
                <Table striped bordered hover size="sm" variant="dark">
                    <tbody>
                    <tr>
                        <th>#</th>
                        <th>user name</th>
                        <th>password</th>
                        <th>company name</th>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                        <td>{id}</td>
                        <td>{username}</td>
                        <td>{password}</td>
                        <td>{company}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        single_user: state.single_user,
    };
}

const SingleUserInfoConnect = connect(mapStateToProps, {getSingleUser})(SingleUserInfo);

export default SingleUserInfoConnect;
