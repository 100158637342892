import React, {Component} from 'react';
import {connect} from "react-redux";
import {getSingleIceStats} from "../../redux/actions";
import SingleIceStatsComp from "./SingleIceStatsComp";


class SingleIceStats extends Component{
    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getSingleIceStats(idx);
    }

    render() {
        let idx = this.props.match.params.idx;
        let val = this.props.single_ice;
        let users = this.props.users;
        return (
            <SingleIceStatsComp idx={idx} single_ice={val} users={users} />
        );
    }
}

function mapStateToProps(state) {
    return {
        single_ice: state.single_ice,
        users: state.users,
    };
}

const SingleIceConnect = connect(mapStateToProps, {getSingleIceStats})(SingleIceStats);

export default SingleIceConnect;