import React, {Component} from 'react';
import UserInfoTable from './UserInfoTable';
import Table from 'react-bootstrap/Table';
import './UserInfo.css';
import {connect} from "react-redux";
import {asyncLoadUser} from "../../redux/actions";


class UserInfo extends Component {

    componentDidMount() {
        this.props.asyncLoadUser();
    }


    render() {
        const users = this.props.users;
        return (
            <div>
                <h1> User Info Table</h1>
                <Table striped bordered hover size="sm" variant="dark">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>user name</th>
                            <th>password</th>
                            <th>company name</th>
                        </tr>
                    </tbody>
                    <tbody>
                    {
                        users.map((userInfo)=>{
                            return  (
                                <UserInfoTable key={userInfo.id} users={userInfo} />
                            );
                        })
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
    };
}

const UserInfoConnect = connect(mapStateToProps, {asyncLoadUser})(UserInfo);

export default UserInfoConnect;
