import React, {Component} from 'react';
import PropTypes from 'prop-types';


class LargehootStatsDetailedTable extends Component {
    static propTypes = {
        errors: PropTypes.number,
        error_types: PropTypes.array.isRequired,
        test_types: PropTypes.number,
        test_cycle: PropTypes.number.isRequired,
        user_name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        remote_user: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        vcname: PropTypes.string.isRequired,
        rx_bit_rate_sec: PropTypes.number,
        tx_bit_rate_sec: PropTypes.number,
        rx_pkt_dur_ms: PropTypes.number,
        tx_pkt_dur_ms: PropTypes.number,
        rx_lost_diff: PropTypes.number,
        tx_lost_diff: PropTypes.number,
        rx_pkt_loss_pcnt: PropTypes.number,
        tx_pkt_loss_pcnt: PropTypes.number,
        rx_aud_lvl_db_m0: PropTypes.number,
        tx_aud_lvl_db_m0: PropTypes.number,
        ice_conn_state: PropTypes.number,
        rx_dist: PropTypes.number,
        timestamp: PropTypes.string.isRequired,
    };

    render() {
        // test type check need to change later
        const test_types = this.props.test_types === (null || 0) ? 'largehoot': this.props.test_types;
        const test_cycle = this.props.test_cycle;
        const username = this.props.user_name;
        const remoteuser = this.props.remote_user;
        const vcname =this.props.vcname;
        const rx_bit_rate_sec = this.props.rx_bit_rate_sec;
        const tx_bit_rate_sec = this.props.tx_bit_rate_sec;
        const rx_pkt_dur_ms = this.props.rx_pkt_dur_ms;
        const tx_pkt_dur_ms = this.props.tx_pkt_dur_ms;
        const rx_pkt_loss_pcnt = this.props.rx_pkt_loss_pcnt;
        const tx_pkt_loss_pcnt = this.props.tx_pkt_loss_pcnt;
        // strange dB db things here
        const rx_aud_lvl_db_m0 = this.props.rx_aud_lvl_db_m0;
        const tx_aud_lvl_db_m0 = this.props.tx_aud_lvl_db_m0;
        const rx_lost_diff = this.props.rx_lost_diff;
        const tx_lost_diff = this.props.tx_lost_diff;
        const ice_conn_state = this.props.ice_conn_state;
        const rx_dist = this.props.rx_dist;
        const timestamp = this.props.timestamp;
        const error = this.props.error;
        const error_types = this.props.error_types;
        let e = error_types.find((err) => error === err.id);
        const error_name = e.error_name;

        let date = new Date(timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' });
        let from_user_to_user = "from "+ username + " to " + remoteuser;
        if (remoteuser === "no remote") {
            from_user_to_user = username;
        }
        if(error_name === "no error") {
            return (
                <tr>
                    <td>{test_types}</td>
                    <td>{test_cycle}</td>
                    <td>{vcname}</td>
                    <td>{from_user_to_user}</td>
                    <td>{error_name}</td>
                    <td>{rx_bit_rate_sec}</td>
                    <td>{tx_bit_rate_sec}</td>
                    <td>{rx_pkt_dur_ms}</td>
                    <td>{tx_pkt_dur_ms}</td>
                    <td>{rx_lost_diff}</td>
                    <td>{tx_lost_diff}</td>
                    <td>{rx_pkt_loss_pcnt}</td>
                    <td>{tx_pkt_loss_pcnt}</td>
                    <td>{rx_aud_lvl_db_m0}</td>
                    <td>{tx_aud_lvl_db_m0}</td>
                    <td>{ice_conn_state}</td>
                    <td>{rx_dist}</td>
                    <td>{date}</td>
                </tr>
            );
        } else {
            return (
                <tr style={{backgroundColor: '#FF0000'}}>
                    <td>{test_types}</td>
                    <td>{test_cycle}</td>
                    <td>{vcname}</td>
                    <td>{from_user_to_user}</td>
                    <td>{error_name}</td>
                    <td>{rx_bit_rate_sec}</td>
                    <td>{tx_bit_rate_sec}</td>
                    <td>{rx_pkt_dur_ms}</td>
                    <td>{tx_pkt_dur_ms}</td>
                    <td>{rx_lost_diff}</td>
                    <td>{tx_lost_diff}</td>
                    <td>{rx_pkt_loss_pcnt}</td>
                    <td>{tx_pkt_loss_pcnt}</td>
                    <td>{rx_aud_lvl_db_m0}</td>
                    <td>{tx_aud_lvl_db_m0}</td>
                    <td>{ice_conn_state}</td>
                    <td>{rx_dist}</td>
                    <td>{date}</td>
                </tr>
            );
        }

    }
}

export default LargehootStatsDetailedTable;