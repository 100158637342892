export const ASYNC_LOAD_USER = "ASYNC_USER_DATA";
export const ASYNC_LOAD_ICE_STATS = 'ASYNC_LOAD_ICE_STATS';
export const ASYNC_LOAD_PEER_STATS = 'ASYNC_LOAD_PEER_STATS';
export const ASYNC_LOAD_GENERAL_STATS = 'ASYNC_LOAD_GENERAL_STATS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_BOTH_USER_AND_TESTS = 'GET_BOTH_USER_AND_TESTS';
export const ERROR = "ERROR";
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const GET_SINGLE_ICE = 'GET_SINGLE_ICE';
export const GET_SINGLE_PEER_STATS = 'GET_SINGLE_PEER_STATS';
export const GET_INTERCOM_STATS = 'GET_INTERCOM_STATS';
export const GET_RINGDOWN_STATS = 'GET_RINGDOWN_STATS';
export const GET_LARGEHOOT_STATS = 'GET_LARGEHOOT_STATS';
export const GET_SINGLE_TEST = 'GET_SINGLE_TEST';
export const GET_ERROR_TYPE = "GET_ERROR_TYPE";
export const GET_SINGLE_GENERAL = 'GET_SINGLE_GENERAL';
export const GET_CLIENT_LOGS = 'GET_CLIENT_LOGS';
export const DOWNLOAD_CLIENT_LOGS = 'DOWNLOAD_CLIENT_LOGS';

export const GET_PREV_PAGE = 'GET_PREV_PAGE';
export const GET_NEXT_PAGE = 'GET_NEXT_PAGE';

// URL query const variables
export const USERS_QUERY = '/controller/users';
export const SINGLE_USER_QUERY = '/controller/users/'
export const ICESTATSWITHNUM_QUERY = '/controller/icestatswithnum';
export const DETAILEDTHREE_QUERY = '/controller/peerstats/detailedthree';
export const ERRORS_QUERY = '/controller/peerstats/errors';
export const GENERAL_QUERY = '/controller/general';
export const PAGE_QUERY = '/controller/general?page=';
export const ICESTATS_QUERY = '/controller/icestats/';
export const PEERSTATS_QUERY = '/controller/peerstats/';
export const CLIENTLOGS_QUERY = '/controller/clientlogs/';

export const INTERCOM_QUERY = '/controller/intercom/';
export const LARGEHOOT_QUERY = '/controller/largehoot/';
export const RINGDOWN_QUERY = '/controller/ringdown/';