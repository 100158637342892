import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Route, BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import UserInfo from './controller/users/UserInfo'
import Header from "./controller/Header";
import IceStats from './controller/icetests/IceStats';
import PeerStatsTest from './controller/peerstats/PeerStatsTest';
import SingleUserInfo from './controller/users/SingleUserInfo';
import store from './redux/store/index';
import {Provider} from "react-redux";
import SingleIceConnect from "./controller/icetests/SingleIceStats";
import SinglePeerStatsConnect from "./controller/peerstats/SinglePeerStats";
import SingleTestNumStats from "./controller/alltests/SingleTestNumStats";
import AllPageTempTests from "./controller/alltests/AllPageTempTests";
import IntercomStatsConnect from "./controller/intercomstats/IntercomStats"
import RingdownStatsConnect from "./controller/ringdownstats/RingdownStats"
import LargehootStatsConnect from "./controller/largehootstats/LargehootStats"

const routing = (
    <Provider store={store}>
        <Router>
            <div className="App">
                <header className="App-header">
                    <Header />
                    <div className="divStyle">
                        <Route exact path="/" component={AllPageTempTests} />
                        <Route exact path="/:idx(\d+)" component={SingleTestNumStats} />
                        <Route exact path="/user" component={UserInfo} />
                        <Route path="/user/:idx" component={SingleUserInfo} />
                        <Route exact path="/icestats" component={IceStats} />
                        <Route exact path="/peerstats" component={PeerStatsTest} />
                        <Route exact path="/peerstats/:idx(\d+)" component={SinglePeerStatsConnect} />
                        <Route exact path="/icestats/:idx(\d+)" component={SingleIceConnect} />
                        <Route exact path="/intercom/:idx(\d+)" component={IntercomStatsConnect} />
                        <Route exact path="/ringdown/:idx(\d+)" component={RingdownStatsConnect} />
                        <Route exact path="/largehoot/:idx(\d+)" component={LargehootStatsConnect} />
                    </div>
                </header>
            </div>
        </Router>
    </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
