import React, {Component} from "react";
import PropTypes from 'prop-types';
import IceStatsTable from "./IceStatsTable";

class SingleIceStatsComp extends Component {
    static propTypes = {
        idx: PropTypes.string.isRequired,
        single_ice: PropTypes.array.isRequired,
        users: PropTypes.array.isRequired,
    };

    render() {
        let idx = this.props.idx;
        let val = this.props.single_ice;
        let users = this.props.users;
        return (
            <div>
                <h1>Ice Stats with Test Num {idx}</h1>
                <IceStatsTable val={val} users={users} />
            </div>
        );
    }
}

export default SingleIceStatsComp;