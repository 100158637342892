import React, {Component} from 'react';
import IceStatsTable from './IceStatsTable';
import {connect} from "react-redux";
import {asyncLoadIce} from "../../redux/actions";

class IceStats extends Component {
    componentDidMount() {
        this.props.asyncLoadIce();
    }

    render() {
        let ice_stats = this.props.ice_stats;
        let users = this.props.users;
        let i = 0;
        return (
            <div>
                {
                    ice_stats.map((ice_detail) => {
                        let key_string = Object.keys(ice_detail)[0];
                        let val = ice_detail[key_string];
                        let key_int = 0;
                        try{
                            key_int = parseInt(key_string);
                        }catch (e) {
                            console.log('key is not a valid integer');
                        }
                        if( i === 0 ){
                            i++;
                            return (
                                <div key={key_int}>
                                    <details open>
                                        <summary>Number of Test Case {key_int}</summary>
                                        <IceStatsTable val={val} users={users} />
                                    </details>
                                </div>
                            );
                        } else {
                            return (
                                <div key={key_int}>
                                    <details>
                                        <summary>Number of Test Case {key_int}</summary>
                                        <IceStatsTable val={val} users={users} />
                                    </details>
                                </div>
                            );
                        }
                    })
                }
            </div>
        );
    };
}

function mapStateToProps(state) {
    return {
        ice_stats: state.ice_stats,
        users: state.users,
    };
}

const IceConnect = connect(mapStateToProps, {asyncLoadIce})(IceStats);

export default IceConnect;