import React, {Component} from 'react';
import {connect} from "react-redux";
import {getSingleTest} from "../../redux/actions";
import SinglePeerStatsComp from "../peerstats/SinglePeerStatsComp";
import SingleIceStatsComp from "../icetests/SingleIceStatsComp";

class SingleTestNumStats extends Component {
    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getSingleTest(idx);
    }

    render() {
        const users = this.props.users;
        let ice = this.props.single_ice;
        let peer = this.props.single_peerstats;
        let {idx} = this.props.match.params;
        const errors = this.props.errors;
        if(ice.length === 0 && peer.length === 0) {
            return (
                <div>no data</div>
            )
        }

        if(ice.length > 0) {
            return (
                <div>
                    <details open>
                        <summary>ice stats with test {idx}</summary>
                        <SingleIceStatsComp idx={idx} single_ice={ice} users={users} />
                    </details>
                </div>
            );
        }

        if(peer.length > 0) {
            return (
                <div>
                    <details open>
                        <summary>peer stats with test {idx}</summary>
                        <SinglePeerStatsComp val={peer} errors={errors} idx={idx} users={users} />
                    </details>
                </div>
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        single_ice: state.single_ice,
        single_peerstats: state.single_peerstats,
        users: state.users,
        errors: state.peer_stats_error_types,
    };
}

const SingleTestNumStatsConnect = connect(mapStateToProps, {getSingleTest})(SingleTestNumStats);

export default SingleTestNumStatsConnect;
