import React, { Component } from 'react';

class UserInfoTable extends Component {
    render() {
        const id = this.props.users.id;
        const username = this.props.users.username;
        const password = this.props.users.password;
        const company = this.props.users.company;

        return (
            <tr>
                <td>{id}</td>
                <td>{username}</td>
                <td>{password}</td>
                <td>{company}</td>
            </tr>
        );
    }
}

export default UserInfoTable;