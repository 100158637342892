import React, {Component} from 'react';
import PropTypes from 'prop-types';


class IceStatsDetailedTable extends Component {
    static propType = {
        test_cycle: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired,
        channel_name: PropTypes.string.isRequired,
        ice_stats: PropTypes.number.isRequired,
        time_stamp: PropTypes.string.isRequired,
        remote_username: PropTypes.string.isRequired,
    };

    render(){
        const test_cycle = this.props.test_cycle;
        const username = this.props.username;
        const channel_name = this.props.channel_name;
        const ice_stats = this.props.ice_stats;
        const time_stamp = this.props.time_stamp;
        const remote_username = this.props.remote_user;

        let date = new Date(time_stamp).toLocaleString('en-US', { timeZone: 'America/New_York' });
        let from_user_to_user = "from "+ username + " to "+ remote_username;

        return (
            <tr>
                <td>{test_cycle}</td>
                <td>{channel_name}</td>
                <td>{from_user_to_user}</td>
                <td>{ice_stats}</td>
                <td>{date}</td>
            </tr>
        );
    };
}

export default IceStatsDetailedTable;