import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ClientLogs from "./ClientLogs";

class AllTestsTable extends Component {

    findUserId = (users, username, val) => {
        users.forEach(user => {
            if(user.username === username) {
                val = user.id;
            }
        });
    };

    render() {
        const num_of_test_case = this.props.general_test.num_of_test_case;
        const testers = this.props.general_test.testers;
        const test_start_time = this.props.general_test.test_start_time;
        const ice_stats_test = this.props.general_test.ice_stats_test;
        const peer_stats_test = this.props.general_test.peer_stats_test;
        const press_button_test = this.props.general_test.press_button_test;
        const has_client_logs = this.props.general_test.has_client_logs;
        const intercom = this.props.general_test.intercom_test;
        const ringdown = this.props.general_test.ringdown_test;
        const largehoot = this.props.general_test.largehoot_test;
        const succeed = this.props.general_test.succeed;
        const users = this.props.users;
        let date = new Date(test_start_time).toLocaleString('en-US', { timeZone: 'UTC' });
        let tester_string = [];

        testers.forEach((tester, index) => {
            let val = -1;
            users.forEach(user => {
                if(user.username === tester) {
                    val = user.id;
                }
            });
            let name = "";
            if(index !== testers.length - 1) {
                name = tester + ", ";
            } else {
                name = tester;
            }
            if(val !== -1) {
                tester_string.push(<Link to={"user/" + val} key={index}> {name} </Link>)
            }
        });


        // link to ice test and peer test
        let ice_link = '/icestats/' + num_of_test_case;
        let peer_link = '/peerstats/' + num_of_test_case;
        let intercom_link = '/intercom/' + num_of_test_case;
        let ringdown_link = '/ringdown/' + num_of_test_case;
        let largehoot_link = '/largehoot/' + num_of_test_case;
        // let press_button_link = '/pressbutton/' + num_of_test_case;

        let ice_stats = ice_stats_test === true?
            <span style={{color: '#00ff00'}}><Link to={ice_link}>&#10004;</Link></span>:
            <span style={{color: '#ff0000'}}>&#10006;</span>;
        let peer_stats = peer_stats_test === true?
            <span style={{color: '#00ff00'}}><Link to={peer_link}>&#10004;</Link></span>:
            <span style={{color: '#ff0000'}}>&#10006;</span>;
        let press_button = press_button_test === true?
            <span style={{color: '#00ff00'}}><Link to={ice_link}>&#10004;</Link></span>:
            <span style={{color: '#ff0000'}}>&#10006;</span>;
        let intercom_stats = intercom === true?
            <span style={{color: '#00ff00'}}><Link to={intercom_link}>&#10004;</Link></span>:
            <span style={{color: '#ff0000'}}>&#10006;</span>;
        let ringdown_stats = ringdown === true?
            <span style={{color: '#00ff00'}}><Link to={ringdown_link}>&#10004;</Link></span>:
            <span style={{color: '#ff0000'}}>&#10006;</span>
        let largehoot_stats = largehoot === true?
            <span style={{color: '#00ff00'}}><Link to={largehoot_link}>&#10004;</Link></span>:
            <span style={{color: '#ff0000'}}>&#10006;</span>;

        // link to single test num
        let test_num_link = '/' + num_of_test_case;
        let single_test = <Link to={test_num_link}>{num_of_test_case}</Link>;

        let succeed_button = succeed === true?
            <span role="img" aria-label="grinning face with big eyes">&#128515;</span>:
            <span role="img" aria-label="pouting face">&#128545;</span>;
        let clientLogs = <ClientLogs hasLogs={has_client_logs} id={num_of_test_case} />;

        return (
            <tr>
                <td>{single_test}</td>
                <td>{tester_string}</td>
                <td>{date}</td>
                <td>{ice_stats}</td>
                <td>{peer_stats}</td>
                <td>{press_button}</td>
                <td>{intercom_stats}</td>
                <td>{ringdown_stats}</td>
                <td>{largehoot_stats}</td>
                <td>{clientLogs}</td>
                <td>{succeed_button}</td>
            </tr>
        );
    }
}

export default AllTestsTable;