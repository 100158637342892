import React from 'react'
import {Navbar, Nav, Form, FormControl, Button, NavDropdown} from "react-bootstrap";
import {Link} from 'react-router-dom';

class Header extends React.Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Navbar.Brand href="/">Test Statistics</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link><Link to="/user">Users</Link></Nav.Link>
                        <NavDropdown title="Test Category" id="basic-nav-dropdown">
                            <NavDropdown.Item><Link to="/icestats">ICE</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to="/peerstats">Peer Stats</Link></NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Press Button</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Form inline>
                        <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                        <Button variant="outline-success">Search</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Header;