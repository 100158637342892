import React, {Component} from 'react';
import {connect} from "react-redux";
import {getRingdownStats} from "../../redux/actions";
import RingdownStatsComp from "./RingdownStatsComp";

class RingdownStats extends Component {
    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getRingdownStats(idx);
    }

    render() {
        const val = this.props.ringdown_stats;
        const idx = this.props.match.params.idx;
        const errors = this.props.peer_stats_error_types;
        const users = this.props.users;
        return <RingdownStatsComp val={val} idx={idx} errors={errors} users={users} />
    }
}

function mapStateToProps(state) {
    return {
        ringdown_stats: state.ringdown_stats,
        peer_stats_error_types: state.peer_stats_error_types,
        users: state.users,
    };
}

const RingdownStatsConnect = connect(mapStateToProps, {getRingdownStats})(RingdownStats);

export default RingdownStatsConnect;