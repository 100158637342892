import React, {Component} from 'react';
import Proptype from 'prop-types';
import IceStatsDetailedTable from './IceStatsDetailedTable';
import Table from 'react-bootstrap/Table';

class IceStatsTable extends Component {
    static propTypes = {
        val: Proptype.array.isRequired,
        users: Proptype.array.isRequired,
    };

    sortArr = (data) => {
      data.sort((a, b) => a.test_cycle-b.test_cycle && a.channel_name-b.channel_name);
      return data;
    };

    render(){
        const val = this.sortArr(this.props.val);
        const users = this.props.users;
        return (
            <Table striped bordered hover size="sm" variant="dark">
                <tbody>
                    <tr>
                        <th>test cycle</th>
                        <th>vcname</th>
                        <th>from user to user</th>
                        <th>ice stats</th>
                        <th>timestamp</th>
                    </tr>
                </tbody>
                <tbody>
                    {
                        val.map((ice)=>{
                            const test_cycle = ice.test_cycle;
                            const username = ice.username_id;
                            const channel_name = ice.channel_name;
                            const ice_stats = ice.ice_stats;
                            const time_stamp = ice.time_stamp;
                            const remote_username = ice.remote_username_id;
                            const unique_key = test_cycle + time_stamp;
                            if(users !== undefined){
                                var local = users.find((user) => user.id === username);
                                var remote = users.find((user) => user.id === remote_username);
                            }
                            if(local === undefined || remote === undefined) {
                                local = username;
                                remote = remote_username;
                            } else {
                                local = local.username;
                                remote = remote.username;
                            }
                            return (
                                <IceStatsDetailedTable
                                    key={unique_key}
                                    test_cycle={test_cycle}
                                    username={local}
                                    channel_name={channel_name}
                                    ice_stats={ice_stats}
                                    time_stamp={time_stamp}
                                    remote_user={remote}
                                />
                            );
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

export default IceStatsTable;