import React, {Component} from 'react';
import Proptype from 'prop-types';
import RingdownStatsDetailedTable from './RingdownStatsDetailedTable'
import Table from 'react-bootstrap/Table';

class RingdownStatsTable extends Component {
    static propTypes = {
        val: Proptype.array.isRequired,
        peer_stats_error_types: Proptype.array.isRequired,
        users: Proptype.array.isRequired,
    };

    sortArr = (data) => {
        data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        return data;
    };

    render() {
        const val = this.sortArr(this.props.val);
        const error_types = this.props.peer_stats_error_types;
        const users = this.props.users;
        return (
            <Table striped bordered hover size="sm" variant="dark">
                <tbody>
                    <tr>
                        <th>test types</th>
                        <th>test cycle</th>
                        <th>vcname</th>
                        <th>from user to user</th>
                        <th>error</th>
                        <th>rx bit rate sec</th>
                        <th>tx bit rate sec</th>
                        <th>rx pkt dur ms</th>
                        <th>tx pkt dur ms</th>
                        <th>rx lost diff</th>
                        <th>tx lost diff</th>
                        <th>rx pkt loss pcnt</th>
                        <th>tx pkt loss pcnt</th>
                        <th>rx aud lvl db m0</th>
                        <th>tx aud lvl db mo</th>
                        <th>ice conn state</th>
                        <th>rx dist</th>
                        <th>timestamp</th>
                    </tr>
                </tbody>
                <tbody>
                {
                    val.map((peer) => {
                        const error = peer.errors;
                        const test_type = peer.test_types;
                        const test_cycle = peer.test_cycle;
                        const username = peer.local_user;
                        const remoteuser = peer.remote_user;
                        const vcname = peer.vcname;
                        const rx_bit_rate_sec = peer.rx_bit_rate_sec;
                        const tx_bit_rate_sec = peer.tx_bit_rate_sec;
                        const rx_pkt_dur_ms = peer.rx_pkt_dur_ms;
                        const tx_pkt_dur_ms = peer.tx_pkt_dur_ms;
                        const rx_pkt_loss_pcnt = peer.rx_pkt_loss_pcnt;
                        const tx_pkt_loss_pcnt = peer.tx_pkt_loss_pcnt;
                        const rx_lost_diff = peer.rx_lost_diff;
                        const tx_lost_diff = peer.tx_lost_diff;
                        const rx_aud_lvl_db_m0 = peer.rx_aud_lvl_db_m0;
                        const tx_aud_lvl_db_m0 = peer.tx_aud_lvl_db_m0;
                        const ice_conn_state = peer.ice_conn_state;
                        const rx_dist = peer.rx_dist;
                        const timestamp = peer.timestamp;
                        const unique_key = test_cycle + timestamp;
                        if (users !== undefined) {
                            var local = users.find((user) => user.id === username);
                            var remote = users.find((user) => user.id === remoteuser);
                        }
                        if (local === undefined || remote === undefined) {
                            local = username;
                            remote = remoteuser;
                        } else {
                            local = local.username;
                            remote = remote.username;
                        }
                        return (
                            <RingdownStatsDetailedTable
                                error_types={error_types}
                                error={error}
                                key={unique_key}
                                test_types={test_type}
                                test_cycle={test_cycle}
                                user_name={local}
                                remote_user={remote}
                                vcname={vcname}
                                rx_bit_rate_sec={rx_bit_rate_sec}
                                tx_bit_rate_sec={tx_bit_rate_sec}
                                rx_pkt_dur_ms={rx_pkt_dur_ms}
                                tx_pkt_dur_ms={tx_pkt_dur_ms}
                                rx_lost_diff={rx_lost_diff}
                                tx_lost_diff={tx_lost_diff}
                                rx_pkt_loss_pcnt={rx_pkt_loss_pcnt}
                                tx_pkt_loss_pcnt={tx_pkt_loss_pcnt}
                                rx_aud_lvl_db_m0={rx_aud_lvl_db_m0}
                                tx_aud_lvl_db_m0={tx_aud_lvl_db_m0}
                                ice_conn_state={ice_conn_state}
                                rx_dist={rx_dist}
                                timestamp={timestamp}
                            />
                        );
                    })
                }
                </tbody>
            </Table>
        );
    }
}

export default RingdownStatsTable;