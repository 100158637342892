import React, {Component} from 'react';
import PeerStatsTable from './PeerStatsTable';
import {connect} from "react-redux";
import {asyncLoadPeerStats} from "../../redux/actions";

class PeerStatsTest extends Component{

    componentDidMount() {
        this.props.asyncLoadPeerStats();
    }

    render() {
        let peer_stats = this.props.peer_stats_info;
        let peer_stats_error_types = this.props.peer_stats_error_types;
        let users = this.props.users;
        let i = 0;
        return (
            <div>
                {
                    peer_stats.map((peer_detail) => {
                        let key_string = Object.keys(peer_detail)[0];
                        let val = peer_detail[key_string];
                        let key_int =0;
                        try{
                            key_int=parseInt(key_string)
                        }catch (e) {
                            console.log('key is not a valid integer');
                        }
                        if(i === 0) {
                            i++;
                            return (
                                <div key={key_int}>
                                    <details open>
                                        <summary>Number of Test Case {key_int}</summary>
                                        <PeerStatsTable
                                            val={val}
                                            peer_stats_error_types={peer_stats_error_types}
                                            users={users}
                                        />
                                    </details>
                                </div>
                            );
                        } else {
                            return (
                                <div key={key_int}>
                                    <details>
                                        <summary>Number of Test Case {key_int}</summary>
                                        <PeerStatsTable
                                            val={val}
                                            users={users}
                                            peer_stats_error_types={peer_stats_error_types}
                                        />
                                    </details>
                                </div>
                            );
                        }
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        peer_stats_info: state.peer_stats_info,
        peer_stats_error_types: state.peer_stats_error_types,
        users: state.users,
    };
}

const PeerStatsConnect = connect(mapStateToProps, {asyncLoadPeerStats})(PeerStatsTest);

export default PeerStatsConnect;