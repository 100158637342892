import React, {Component} from 'react';
import {connect} from "react-redux";
import {getSinglePeerStats} from "../../redux/actions";
import SinglePeerStatsComp from "./SinglePeerStatsComp";


class SinglePeerStats extends Component {
    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getSinglePeerStats(idx);
    }

    render() {
        const val = this.props.single_peerstats;
        const idx = this.props.match.params.idx;
        const errors = this.props.peer_stats_error_types;
        const users = this.props.users;
        return (
            <SinglePeerStatsComp val={val} idx={idx} errors={errors} users={users} />
        );
    }
}

function mapStateToProps(state) {
    return {
        single_peerstats: state.single_peerstats,
        peer_stats_error_types: state.peer_stats_error_types,
        users: state.users,
    };
}

const SinglePeerStatsConnect = connect(mapStateToProps, {getSinglePeerStats})(SinglePeerStats);

export default SinglePeerStatsConnect;