import {
    ASYNC_LOAD_USER, ERROR,
    ASYNC_LOAD_GENERAL_STATS,
    ASYNC_LOAD_ICE_STATS,
    ASYNC_LOAD_PEER_STATS,
    GET_USER_INFO,
    GET_SINGLE_USER,
    GET_SINGLE_ICE,
    GET_SINGLE_PEER_STATS,
    GET_INTERCOM_STATS,
    GET_LARGEHOOT_STATS,
    GET_RINGDOWN_STATS,
    GET_SINGLE_TEST,
    GET_ERROR_TYPE,
    GET_SINGLE_GENERAL,
    GET_CLIENT_LOGS,
    DOWNLOAD_CLIENT_LOGS,
    GET_PREV_PAGE,
    GET_NEXT_PAGE,
    USERS_QUERY,
    ICESTATSWITHNUM_QUERY,
    DETAILEDTHREE_QUERY,
    ERRORS_QUERY,
    GENERAL_QUERY,
    PAGE_QUERY,
    ICESTATS_QUERY,
    PEERSTATS_QUERY,
    CLIENTLOGS_QUERY,
    SINGLE_USER_QUERY,
    INTERCOM_QUERY,
    LARGEHOOT_QUERY,
    RINGDOWN_QUERY
} from "../constants/action-types"
import 'whatwg-fetch';
import {fetchHelper} from "../../controller/Utils";
//import 'aws-sdk';
const AWS = require('aws-sdk');
export const full_test_length = 20;

function sortAscendingByID(data) {
    data.sort((a, b) => a.id - b.id);
    return data;
}

function sortDescendingByID(data) {
    data.sort((a, b) => b.id - a.id);
    return data;
}

function sortDescendingByTestNum(data) {
    data.sort((a, b) => b.num_of_test_case - a.num_of_test_case);
    return data;
}

export function asyncLoadUser() {
    return function(dispatch, getState) {
        let fetchHelperArray = fetchHelper(USERS_QUERY);
        let endpoint = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        if (users.length !== 0) {
            return;
        }
        return fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function(responseData){
                let data = sortAscendingByID(responseData);
                return dispatch({type: ASYNC_LOAD_USER, payload: data});
            }).catch(function (error) {
                return dispatch({type:ERROR, payload: error});
            });
    }
}

export function asyncLoadIce() {
    return function(dispatch, getState) {
        const state = getState();
        const {users} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        let fetchHelperArray = fetchHelper(ICESTATSWITHNUM_QUERY);
        let endpoint = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        return fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function (responseData) {
                return dispatch({type: ASYNC_LOAD_ICE_STATS, payload: responseData});
            }).catch((err) => {
                return dispatch({type:ERROR, payload: err})
            });
    }
}

export function asyncLoadPeerStats() {
    return function(dispatch, getState) {
        let fetchHelperArray = fetchHelper(DETAILEDTHREE_QUERY);
        let endpoint = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        const {peer_stats_error_types} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        if(peer_stats_error_types.length === 0) {
            dispatch(asyncGetErrorTypes());
        }
        return fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function(responseData){
                return dispatch({type: ASYNC_LOAD_PEER_STATS, payload: responseData});
        }).catch((err) => {
            return dispatch({type:ERROR, payload: err});
            });
    }
}

export function asyncLoadPeerStatsAndErrorTypes() {
    return function(dispatch) {
        let fetchHelperArray = fetchHelper(DETAILEDTHREE_QUERY);
        let endpoint = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function(responseData){
                return dispatch({type: ASYNC_LOAD_PEER_STATS, payload: responseData});
            });
        fetchHelperArray = fetchHelper(ERRORS_QUERY);
        endpoint = fetchHelperArray[0];
        lookupOptions = fetchHelperArray[1];
        fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function(responseData){
                return dispatch({type: GET_ERROR_TYPE, payload: responseData});
            });
    }
}

export function asyncGetErrorTypes() {
    return function(dispatch) {
        let fetchHelperArray = fetchHelper(ERRORS_QUERY);
        let endpoint = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        return fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function(responseData){
                return dispatch({type: GET_ERROR_TYPE, payload: responseData});
            });
    }
}

export function asyncLoadGeneralTests() {
    return function(dispatch) {
        let fetchHelperArray = fetchHelper(GENERAL_QUERY);
        let endpoint = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        return fetch(endpoint, lookupOptions)
            .then(response => response.json())
            .then(function(responseData) {
                let data = sortDescendingByTestNum(responseData);
                return dispatch({type:ASYNC_LOAD_GENERAL_STATS, payload: data});
        });
    }
}

export function getBothUserAndTests() {
    return dispatch => {
        let fetchHelperTests = fetchHelper(GENERAL_QUERY);
        let endpointTests = fetchHelperTests[0];
        let lookupOptionsTests = fetchHelperTests[1];
        fetch(endpointTests, lookupOptionsTests)
            .then(response => response.json())
            .then(function(responseData) {
                let data = sortDescendingByTestNum(responseData);
                return dispatch({type:ASYNC_LOAD_GENERAL_STATS, payload: data});
        });
        let fetchHelperUsers = fetchHelper(USERS_QUERY);
        let endpointUsers = fetchHelperUsers[0];
        let lookupOptionsUsers = fetchHelperUsers[1];
        fetch(endpointUsers, lookupOptionsUsers)
            .then(response => response.json())
            .then(function(responseData){
                let data = sortAscendingByID(responseData);
                return dispatch({type: ASYNC_LOAD_USER, payload: data});
            }).catch(function (error) {
            return dispatch({type:ERROR, payload: error});
        });
    }
}

/** function triggered when prev button is clicked, if previous page of test results has already been viewed,
then test results can be loaded from test_map that saves those test results, otherwise, it will FETCH the
test result from website and save the test results in test_map
*/
 export function getPageTestsFromPrevButton(target_page, url) {
     return (dispatch, getState) => {
         let state = getState();
         const {test_map} = state;
         const {peer_stats_error_types} = state;
         const {users} = state;
         state.tests = [];
         let fetchHelperTests = fetchHelper(url);
         let endpointTests = fetchHelperTests[0];
         let lookupOptionsTests = fetchHelperTests[1];
         if ((target_page in test_map) === false){
             fetch(endpointTests, lookupOptionsTests)
                 .then(response => response.json())
                 .then(function(responseData) {
                     if (responseData.length === 0) {
                         test_map[target_page] = [];
                         return;
                     }
                     else {
                         state.current_page = target_page;
                         test_map[target_page] = responseData;
                         return dispatch({type: ASYNC_LOAD_GENERAL_STATS, payload: responseData});
                     }

                 });
         }
         else {
             state.current_page = target_page;
             return dispatch({type: GET_PREV_PAGE})
         }
     }

 }

/** function triggered when next and search button is clicked, if test results have already been viewed,
then test results can be loaded from test_map that saves those test results, otherwise, it will FETCH the
test results from website and save the test results in test_map
*/
 export function getPageTestsFromNextButton(target_page, url) {
     return (dispatch, getState) => {
         let state = getState();
         const {test_map} = state;
         const {peer_stats_error_types} = state;
         const {users} = state;
         state.tests = [];
         let fetchHelperTests = fetchHelper(url);
         let endpointTests = fetchHelperTests[0];
         let lookupOptionsTests = fetchHelperTests[1];
         if ((target_page in test_map) === false){
             fetch(endpointTests, lookupOptionsTests)
                 .then(response => response.json())
                 .then(function(responseData) {
                     if (responseData.length === 0) {
                         test_map[target_page] = [];
                         return;
                     }
                     else {
                         state.current_page = target_page;
                         test_map[target_page] = responseData;
                         return dispatch({type: ASYNC_LOAD_GENERAL_STATS, payload: responseData});
                     }

                 });
         }
         else {
             state.current_page = target_page;
             return dispatch({type: GET_NEXT_PAGE})
         }
     }

 }

// function triggered when you load the page initially (load first page)
 export function getAllPageInfoForTests(current_page) {
      return (dispatch, getState) => {
         let state = getState();
         state.tests = [];
         const {users} = state;
         const {peer_stats_error_types} = state;
         const {tests} = state;
         const {test_map} = state;
         if ((current_page in test_map) === false) {
             let fetchHelperTests = fetchHelper(PAGE_QUERY + current_page);
             let endpointTests = fetchHelperTests[0];
             let lookupOptionsTests = fetchHelperTests[1];
             fetch(endpointTests, lookupOptionsTests)
                 .then(function(response) {
                     return response.json()
                 })
                 .then(function(responseData) {
                     if (responseData.length === 0) {
                         test_map[current_page] = [];
                         return;
                     }
                     test_map[current_page] = responseData
                     return dispatch({type: ASYNC_LOAD_GENERAL_STATS, payload: responseData});
                 });
         }
         if (users.length === 0) {
             dispatch(asyncLoadUser());
         }
         if (peer_stats_error_types.length === 0) {
             dispatch(asyncGetErrorTypes());
         }
     }

 }

export function getSingleUser(id) {
    return dispatch => {
        let url = SINGLE_USER_QUERY + id;
        let fetchHelperArray = fetchHelper(url);
        let endpointUsers = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        return fetch(endpointUsers, lookupOptions)
                .then(response => response.json())
                .then(responseData => dispatch({
                    type: GET_SINGLE_USER,
                    payload: responseData,
                })).catch(error => dispatch({
                type: ERROR,
                payload:error,
                }));
    }
}

export function getSingleIceStats(id) {
    return (dispatch, getState) => {
        let url = ICESTATS_QUERY + id;
        let fetchHelperArray = fetchHelper(url);
        let endpointIce= fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        return fetch(endpointIce, lookupOptions)
            .then(response => response.json())
            .then(responseData => {
                let data = responseData.sort((a,b)=>a.test_cycle-b.test_cycle);
                return dispatch({
                type: GET_SINGLE_ICE,
                payload: data,
                });
            }).catch(error => dispatch({
                type: ERROR,
                payload: error,
            }));
    }
}

export function getLargehootStats(id) {
    return (dispatch, getState) => {
        let url = LARGEHOOT_QUERY + id;
        let fetchHelperArray = fetchHelper(url);
        let endpointLargehootStats = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        const {peer_stats_error_types} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        if (peer_stats_error_types.length === 0) {
            dispatch(asyncLoadPeerStatsAndErrorTypes());
        }
        return fetch(endpointLargehootStats, lookupOptions)
            .then(response => response.json())
            .then(responseData => {
                let data = responseData.sort((a, b) => a.test_cycle - b.test_cycle && a.test_types - b.test_types);
                return dispatch({
                    type: GET_LARGEHOOT_STATS,
                    payload: data,
                });
            }).catch(error => dispatch({
                type: ERROR,
                payload: error,
            }))
    }

}

export function getRingdownStats(id) {
    return (dispatch, getState) => {
        let url = RINGDOWN_QUERY + id;
        let fetchHelperArray = fetchHelper(url);
        let endpointRingdownStats = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        const {peer_stats_error_types} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        if (peer_stats_error_types.length === 0) {
            dispatch(asyncLoadPeerStatsAndErrorTypes());
        }
        return fetch(endpointRingdownStats, lookupOptions)
            .then(response => response.json())
            .then(responseData => {
                let data = responseData.sort((a, b) => a.test_cycle - b.test_cycle && a.test_types - b.test_types);
                return dispatch({
                    type: GET_RINGDOWN_STATS,
                    payload: data,
                });
            }).catch(error => dispatch({
                type: ERROR,
                payload: error,
            }))
    }
}


export function getIntercomStats(id) {
    return (dispatch, getState) => {
        let url = INTERCOM_QUERY + id;
        let fetchHelperArray = fetchHelper(url);
        let endpointIntercomStats = fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        const {peer_stats_error_types} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        if (peer_stats_error_types.length === 0) {
            dispatch(asyncLoadPeerStatsAndErrorTypes());
        }
        return fetch(endpointIntercomStats, lookupOptions)
            .then(response => response.json())
            .then(responseData => {
                let data = responseData.sort((a, b) => a.test_cycle - b.test_cycle && a.test_types - b.test_types);
                return dispatch({
                    type: GET_INTERCOM_STATS,
                    payload: data,
                });
            }).catch(error => dispatch({
                type: ERROR,
                payload: error,
            }))
    }
}

export function getSinglePeerStats(id) {
    return (dispatch, getState) => {
        let url = PEERSTATS_QUERY + id;
        let fetchHelperArray = fetchHelper(url);
        let endpointPeerStats= fetchHelperArray[0];
        let lookupOptions = fetchHelperArray[1];
        const state = getState();
        const {users} = state;
        const {peer_stats_error_types} = state;
        if (users.length === 0) {
            dispatch(asyncLoadUser());
        }
        if (peer_stats_error_types.length === 0){
            dispatch(asyncLoadPeerStatsAndErrorTypes());
        }
        return fetch(endpointPeerStats, lookupOptions)
            .then(response => response.json())
            .then(responseData => {
                let data = responseData.sort((a,b) => a.test_cycle-b.test_cycle && a.test_types-b.test_types);
                return dispatch({
                    type: GET_SINGLE_PEER_STATS,
                    payload: data,
                });
            }).catch(error => dispatch({
                type: ERROR,
                payload: error,
            }));
    }
}

export function getSingleTest(id) {
    return dispatch => {
        dispatch(getSingleIceStats(id));
        dispatch(getSinglePeerStats(id));
    }
}

export function getClientLogs(id) {
    return (dispatch) => {
        let url = CLIENTLOGS_QUERY + id;
        let fetchHelperClientlogs = fetchHelper(url);
        let endpointClientlogs = fetchHelperClientlogs[0];
        let lookupOptionsClientlogs = fetchHelperClientlogs[1];
        fetch(endpointClientlogs, lookupOptionsClientlogs)
            .then(response => response.json())
            .then(function(responseData) {
                return dispatch({type:GET_CLIENT_LOGS, payload: responseData});
            });
    }
}

export function downloadClientlogs(id) {
    return (dispatch) => {
        let arn = 'arn:aws:iam::003924476055:role/OrganizationAccountAccessRole';
        let sts = new AWS.STS();
        sts.assumeRole({
            RoleArn: arn,
            RoleSessionName: 'shiyu',
        }, function(err, data) {
            if(err) {
                console.log(err, err.stack);
            } else {
                AWS.config.update(
                    {
                        accessKeyId: 'AKIAZYE3GJWL54PGY7WR',
                        secretAccessKey: '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',
                    }
                );
                let filename = 'clientlogs/clientlog'+ id + '.zip'
                let s3 = new AWS.S3()
                s3.getObject({
                    Bucket:"c9automation",
                    Key: filename,
                }, function (err, data) {
                    if (err != null) {
                        alert('failed to retrieve file from s3');
                        return dispatch({type: ERROR})
                    } else {
                        alert('data is loaded');
                        return dispatch({type: DOWNLOAD_CLIENT_LOGS, payload:data});
                    }
                })
            }
        });
    }
}