import React, {Component} from 'react';
import {connect} from "react-redux";
import {getIntercomStats} from "../../redux/actions";
import IntercomStatsComp from "./IntercomStatsComp";

class IntercomStats extends Component {
    componentDidMount() {
        const {idx} = this.props.match.params;
        this.props.getIntercomStats(idx);
    }

    render() {

        const val = this.props.intercom_stats;
        const idx = this.props.match.params.idx;
        const errors = this.props.peer_stats_error_types;
        const users = this.props.users;
        return <IntercomStatsComp val={val} idx={idx} errors={errors} users={users} />

    }
}

function mapStateToProps(state) {
    return {
        intercom_stats: state.intercom_stats,
        peer_stats_error_types: state.peer_stats_error_types,
        users: state.users,
    };
}

const IntercomStatsConnect = connect(mapStateToProps, {getIntercomStats})(IntercomStats);

export default IntercomStatsConnect;