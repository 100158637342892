export const LocalHost = 'http://localhost';
export const LocalPort = '8000';
export const LocalURL = LocalHost + ':' + LocalPort;
export const RemoteHost = 'https://media-server.dev1.use1.xhoot.com';
export const RemotePort = '443';
export const RemoteURL = RemoteHost + ':' + RemotePort;

let fetchHelper = (url) => {
    const endpoint = RemoteURL + url;
    let lookupOptions = {
        method : "GET",
        headers : {
            'Content-Type': 'application/json'
        }
    };
    return [endpoint, lookupOptions];
};

let fetchDownload = (url) => {
    const endpoint = RemoteURL + url;
    let lookupOptions = {
        method : "GET",
        headers : {
            'Content-Type': 'application/zip'
        }
    };
    return [endpoint, lookupOptions];
};


export {fetchHelper, fetchDownload};